import { navigate } from 'gatsby';
import { atom } from 'recoil';
import { getRecoil, setRecoil } from 'recoil-nexus';
import {
  SendOTPResponse,
  SSOResponse,
  VerifyOTPResponse
} from '../../helpers/RequestResponse';
import { useSetRecoilState } from 'recoil';
import Api from '../../helpers/Api';
import { pushAnalyticsEvent } from '../../helpers/utils';
import SETTINGS from '../../constants/appSettings';
import { CODE, ERROR } from '../../constants/appCopy.json';
import { loginBannerErrorState } from '../LoginView/utils';
import { flowConfigState } from '../..';

export const otpTriggerState = atom({
  key: 'otpTriggerState',
  default: {
    code: '',
    message: '',
    status: '',
    mode: '',
    count: '',
    contact: '',
    referenceId: ''
  }
});

export const resetOtpTriggerState = atom({
  key: 'resetOtpTriggerState',
  default: {}
});

export const otpVerifyState = atom({
  key: 'otpVerifyState',
  default: {}
});

export const exchangeState = atom({
  key: 'exchangeState',
  default: {}
});

export function handleOTPErrorsToLoginPage(
  response: SendOTPResponse | VerifyOTPResponse | SSOResponse
) {
  if (response?.body) {
    return null;
  } else {
    if (response?.error?.data?.code === CODE.CHALLENGE_CHECK_REQUIRED) {
      return null;
    } else {
      if (
        response?.error?.data?.code === CODE.CONTACT_EMAIL_NOT_FOUND_FOR_OTP
      ) {
        return ERROR.FURTHER_ASSISTANCE;
      } else {
        return response?.error?.data?.code || ERROR.SOMETHING_WRONG;
      }
    }
  }
}

export function prepareRedirect(path, params) {
  const targetUrl = sessionStorage.getItem('targetUrl');
  let url = new URL(path);
  if (params.accessToken) {
    url.searchParams.set('accessToken', params.accessToken);
  }
  if (params.refreshToken) {
    url.searchParams.set('refreshToken', params.refreshToken);
  }
  if (targetUrl) {
    url.searchParams.set('targetUrl', targetUrl);
  }
  return encodeURIComponent(url.toString());
}

export function exchangeToChannel(
  flowConfig = getRecoil(flowConfigState),
  ssoResponse = getRecoil(exchangeState)
) {
  const userid = sessionStorage.getItem('userId');
  const url = SETTINGS.GC_SM_TARGET;

  sessionStorage.removeItem('userId');
  sessionStorage.removeItem('flowId');
  sessionStorage.removeItem('targetUrl');
  if (flowConfig?.type === 'token') {
    const callbackUrl =
      flowConfig.portal === 'UIAM'
        ? `${ssoResponse?.body?.callbackUrl}&targetUrl=${SETTINGS.LANDING_PAGE}`
        : ssoResponse?.body?.callbackUrl;
    window.location.href = callbackUrl;
  }
}

export async function exchangeToken(
  verifyResponse,
  flowConfig,
  userConfig,
  setLoginBannerError,
  setRoute,
  setToast
) {
  let payload = { ...verifyResponse?.body };
  if (userConfig.isEmailVerify) {
    payload = { ...payload, isEmailVerify: true };
  }
  const ssoResponse = await Api.getSSO(payload);
  setRecoil(exchangeState, ssoResponse);
  if (ssoResponse?.body) {
    pushAnalyticsEvent(
      'uiam_login',
      'login_complete',
      `${flowConfig?.portal} - Login with MFA completed`
    );

    if (
      userConfig.isPostLogin &&
      ssoResponse?.body?.code !== 'BILLPAYER_VERIFICATION_FAILED'
    ) {
      let msg = 'Email verified';
      if (ssoResponse?.body?.code === 'ADD_CONTACT_SUCCESS') {
        msg =
          userConfig.userFlow === 'addMobileComplete'
            ? 'Mobile number added to profile'
            : 'Email added to profile';
      }
      if (setToast) {
        setToast({
          show: true,
          text: msg
        });
      }
    }

    if (ssoResponse?.body?.code === 'START_BILLPAYER_VERIFICATION') {
      Api._setToken(ssoResponse?.body?.accessToken); // 1FA
      setRoute('bpvNric');
      return;
    }

    if (
      flowConfig?.portal?.toLowerCase() === 'wfm' ||
      flowConfig?.portal?.toLowerCase() === 'fs' ||
      flowConfig?.portal?.toLowerCase() === 'vsop'
    ) {
      navigate(`/profilecomplete${window.location.search}`);
      return;
    }

    if(flowConfig?.portal?.toLowerCase() === 'shirley') {
      navigate(`/profilecomplete${window.location.search}`);
      return;
    }
    
    
    exchangeToChannel(flowConfig, ssoResponse);
  } else {
    setLoginBannerError(ERROR.SOMETHING_WRONG);
    navigate(`/login${window.location.search}`);
  }
}
